import { DropdownArrPropsType } from "DataTypes/Form";
import {
  CPApiCatQuesType,
  CPApiOptType,
  CPApiQuesUpdate,
  CPProblemArr,
  CPProblemRead,
  CPSearchType,
  CPSummaryQuesOptType,
  CPSummaryQuesType,
  CPSummaryTableQuesType,
  CPSummaryTableType,
  CPSummaryType,
  CPTeamAddProviderType,
  CPTeamSearchType,
  TCMSummaryTableType,
} from "DataTypes/Services/Careplan.type";
import {
  AscendSort,
  CheckArr,
  CheckBoolean,
  CheckCPComprehensive,
  CheckNumber,
  CheckStringEmpty,
  CheckStringId,
} from "./common";
import DateTime from "./DateTime";

const CreateassignTeamDropData: (
  data: any,
  idKey: string,
  labelKey1: string,
  labelKey2: string,
  valKey: string
) => DropdownArrPropsType[] = (data, idKey, labelKey1, labelKey2, valKey) => {
  return data?.map((el: any) => ({
    id: el[idKey],
    label: el[labelKey1] + " " + el[labelKey2],
    value: el[valKey],
  }));
};

const CPSummaryArr: (data: any) => CPSummaryType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: CPSummaryType[] = CheckedArr.map((el) => CPSummary(el));
  return AscendSort(newArr, "problemName");
};

const CPSummary: (data: any) => CPSummaryType = (data) => {
  return {
    problemId: CheckNumber(data?.problemId),
    problemName: CheckStringEmpty(data?.problemName),
    careplanQuetion: CPSummaryQuesArr(data?.careplanQuetion),
  };
};

const CPSummaryQuesArr: (data: any) => CPSummaryQuesType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr = CheckedArr.map((el) => CPSummaryQues(el));
  return newArr;
};

const CPSummaryQues: (data: any) => CPSummaryQuesType = (data) => {
  return {
    careplanQuestionId: CheckNumber(data?.careplanQuestionId),
    careplanQuestionName: CheckStringEmpty(data?.careplanQuestionName),
    careplanSummaryQuestionOptions: CPSummaryQuesOpt(
      data?.careplanSummaryQuestionOptions
    ),
    category_text: CheckStringEmpty(data?.category_text),
    categorytype: CheckStringEmpty(data?.categorytype),
    freeTextAnswer: CheckStringEmpty(data?.freeTextAnswer),
    questionComment: CheckStringEmpty(data?.questionComment),
  };
};

const CPSummaryQuesOpt: (data: any) => CPSummaryQuesOptType[] = (data) => {
  const CheckedArr = CheckArr(data);
  const newArr: CPSummaryQuesOptType[] = CheckedArr.map((el) => {
    return {
      optionId: CheckNumber(el?.optionId),
      optionTest: CheckStringEmpty(el?.optionTest),
    };
  });
  return newArr;
};

const FormateCarePlanSummary: (res: any) => CPSummaryTableType[] = (res) => {
  const CheckRes = CPSummaryArr(res);
  const newArr: CPSummaryTableType[] = CheckRes.map((el) => {
    const GoalQuesArr = el.careplanQuetion.filter(
      (QuesEl) => QuesEl.categorytype == "Goals"
    );
    const InterQuesArr = el.careplanQuetion.filter(
      (QuesEl) => QuesEl.categorytype == "Interventions"
    );
    return {
      id: el.problemId,
      problem: el.problemName,
      goal: FormateApiQuesToTableQues(GoalQuesArr),
      intervention: FormateApiQuesToTableQues(InterQuesArr),
    };
  });
  return newArr;
};

const FormateTcmSummary: (res: any) => TCMSummaryTableType[] = (res) => {
  return [
    {
      id: 1,
      tcmProblem: getTcmProblems(CheckStringEmpty(res?.problemName)),
      goal: FormateApiQuesToTableQues(res?.careplanQuetion),
      problemId: CheckStringEmpty(res?.problemId),
      problemIcdCode: CheckNumber(res?.problemIcdCode),
    },
  ];
};

const getTcmProblems = (data: string) => {
  const newArr = data.split(",");
  return newArr
    .filter((el) => {
      return el !== "null";
    })
    .sort();
};

const FormateApiQuesToTableQues: (
  data: CPSummaryQuesType[]
) => CPSummaryTableQuesType[] = (data) => {
  const newArr: CPSummaryTableQuesType[] = data.map((el) => {
    return {
      id: el?.careplanQuestionId,
      label: el?.careplanQuestionName,
      comment: el?.questionComment,
      freeTextAnswer: el?.freeTextAnswer,
      options: el?.careplanSummaryQuestionOptions.map((el1) => {
        return {
          id: el1?.optionId,
          label: el1?.optionTest,
        };
      }),
    };
  });
  return newArr;
};

const CheckCPSearch: (data: any) => CPSearchType = (data) => {
  return {
    careplan_frequency: CheckStringEmpty(data?.careplan_frequency),
    carePlanAdditionalNotes: CheckStringEmpty(data?.carePlanAdditionalNotes),
    careplanCompletionDate: DateTime.ValidDate(data?.careplanCompletionDate),
  };
};
const CheckRecurringCPSearch: (data: any) => CPSearchType = (data) => {
  return {
    carePlanAdditionalNotes: CheckStringEmpty(data?.additionalNotes),
    careplan_frequency: CheckStringEmpty(data?.careplan_frequency),
    careplanCompletionDate: DateTime.ValidDate(data?.recurringCompletedDatetime),
  };
};

const CheckCarePlanQuesArr: (res: any) => CPApiCatQuesType[] = (res) => {
  const CheckedArr = CheckArr(res);
  const newArr: CPApiCatQuesType[] =
    CheckedArr.map((el) => {
      return CheckCarePlanQuesObj(el);
    }) || [];
  return newArr;
};

const CheckCarePlanQuesObj: (resObj: any) => CPApiCatQuesType = (resObj) => {
  return {
    categoryId: CheckNumber(resObj?.category_id),
    category: CheckStringEmpty(resObj?.category),
    careplanQuestionId: CheckNumber(resObj?.careplan_question_id),
    isAnswered: CheckBoolean(resObj?.is_answered),
    parentOptionId: CheckNumber(resObj?.parent_option_id),
    parentQuestionId: CheckNumber(resObj?.parent_question_id),
    question: CheckStringEmpty(resObj?.question),
    questionComment: CheckStringEmpty(resObj?.question_comment),
    questionType: CheckStringEmpty(resObj?.question_type),
    questionsId: CheckNumber(resObj?.questions_id),
    requiredQuestion: CheckBoolean(resObj?.required_question),
    programId: CheckNumber(resObj?.program_id),
    isRequiredOptionAnswered: CheckBoolean(
      resObj?.is_required_options_answered
    ),
    freeTextAnswer: CheckStringEmpty(resObj?.freeTextAnswer),
  };
};

const CheckCarePlanQuesOpt: (res: any) => CPApiOptType[] = (res) => {
  const CheckedArr = CheckArr(res);
  const newArr: CPApiOptType[] =
    CheckedArr.map((el) => {
      return {
        id: CheckNumber(el?.option_id),
        option: CheckStringEmpty(el?.option),
        requiredOption: CheckBoolean(el?.required_option),
        answered: CheckBoolean(el?.answered),
        careplanQuestionOptionId: CheckNumber(el?.careplan_option_id),
      };
    }) || [];
  return newArr;
};

const CheckCarePlanQuesUpdateObj: (resObj: any) => CPApiQuesUpdate = (
  resObj
) => {
  const checkedArr = CheckArr(resObj?.careplanQuestionOption);
  const newArr = checkedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      optionId: CheckNumber(el?.optionId),
    };
  });
  return {
    id: CheckNumber(resObj?.id),
    careplanQuestionOption: newArr,
  };
};

const CheckCPProblemRead: (res: any) => CPProblemRead = (res) => {
  return {
    isCareplanComprehensive: CheckCPComprehensive(res?.isCareplanComprehensive),
    careplanProblem: CheckCPProblemArr(res?.careplanProblem),
  };
};

const CheckCPProblemArr: (res: any) => CPProblemArr[] = (res) => {
  const checkedArr = CheckArr(res);
  return checkedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      icdCode: CheckNumber(el?.icdCode),
      problemId: CheckNumber(el?.problemId),
    };
  });
};

const CheckCPTeam: (res: any) => CPTeamSearchType = (res) => {
  const AddProviderArr = CheckCPTeamAddProvider(
    res?.careplanAdditionalProvider
  );
  const caremanagerName = CheckStringEmpty(res?.caremanagerName);
  const caremanagerDes = CheckStringEmpty(res?.caremanagerDesignation);
  const caremanagerNameDes = caremanagerName
    ? caremanagerName + `${caremanagerDes ? ", " + caremanagerDes : ""}`
    : "";
  const providerName = CheckStringEmpty(res?.teamProviderName);
  const providerDes = CheckStringEmpty(res?.teamProviderDesignation);
  const providerNameDes = providerName
    ? providerName + `${providerDes ? ", " + providerDes : ""}`
    : "";
  const billProviderName = CheckStringEmpty(res?.billableProviderName);
  const billProviderDes = CheckStringEmpty(res?.billableProviderDesignation);
  const billProviderNameDes = billProviderName
    ? billProviderName + `${billProviderDes ? ", " + billProviderDes : ""}`
    : "";
  const primaryCareCoordinatorName = CheckStringEmpty(
    res?.primaryCareCoordinatorName
  );
  const primaryCareCoordinatorDes = CheckStringEmpty(
    res?.primaryCareCoordinatorDesignation
  );
  const primaryCareCoordinatorNameDes = primaryCareCoordinatorName
    ? primaryCareCoordinatorName +
      `${primaryCareCoordinatorDes ? ", " + primaryCareCoordinatorDes : ""}`
    : "";
  const secondaryCareCoordinatorName = CheckStringEmpty(
    res?.secondaryCareCoordinatorName
  );
  const secondaryCareCoordinatorDes = CheckStringEmpty(
    res?.secondaryCareCoordinatorDesignation
  );
  const secondaryCareCoordinatorNameDes = secondaryCareCoordinatorName
    ? secondaryCareCoordinatorName +
      `${secondaryCareCoordinatorDes ? ", " + secondaryCareCoordinatorDes : ""}`
    : "";

  return {
    careplanId: CheckNumber(res?.careplanId),
    programId: CheckNumber(res?.programId),
    patientId: CheckStringId(res?.patientId),
    practiceId: CheckNumber(res?.practiceId),
    practiceLocationId: CheckNumber(res?.practiceLocationId),
    caremanagerId: CheckNumber(res?.caremanagerId),
    caremanagerName: caremanagerNameDes,
    providerId: CheckNumber(res?.teamProviderId),
    providerName: providerNameDes,
    primaryCareCoordinatorId: CheckNumber(res?.primaryCareCoordinatorId),
    primaryCareCoordinatorName: primaryCareCoordinatorNameDes,
    secondaryCareCoordinatorId: CheckNumber(res?.secondaryCareCoordinatorId),
    secondaryCareCoordinatorName: secondaryCareCoordinatorNameDes,
    speciality: CheckStringEmpty(res?.teamProviderSpeciality),
    authorizedRepresentative1: CheckStringEmpty(res?.authorizedRepresentative1),
    authorizedRepresentative2: CheckStringEmpty(res?.authorizedRepresentative2),
    authorizedRepresentative3: CheckStringEmpty(res?.authorizedRepresentative3),
    billableProviderId: CheckNumber(res?.billableProviderId),
    billableProviderName: billProviderNameDes,
    careplanAdditionalProvider: AddProviderArr,
  };
};

const CheckCPTeamAddProvider: (res: any) => CPTeamAddProviderType[] = (res) => {
  const CheckedArr = CheckArr(res);
  return CheckedArr.map((el) => {
    return {
      id: CheckNumber(el?.id),
      careplanId: CheckNumber(el?.careplanId),
      programId: CheckNumber(el?.programId),
      patientId: CheckNumber(el?.patientId),
      practiceId: CheckNumber(el?.practiceId),
      practiceLocationId: CheckNumber(el?.practiceLocationId),
      additionalProviderName: CheckStringEmpty(el?.additionalProviderName),
      additionalProviderSpeciality: CheckStringEmpty(
        el?.additionalProviderSpeciality
      ),
      addProviderOrganization: CheckStringEmpty(el?.addProviderOrganization),
      phoneNumber: CheckStringEmpty(el?.phoneNumber),
      faxNumber: CheckStringEmpty(el?.faxNumber),
      isDeleted: CheckBoolean(el?.isDeleted),
    };
  });
};

export default {
  CreateassignTeamDropData,
  CPSummary,
  CPSummaryQues,
  CPSummaryQuesOpt,
  FormateCarePlanSummary,
  CheckCPSearch,
  CheckCarePlanQuesArr,
  CheckCarePlanQuesObj,
  CheckCarePlanQuesOpt,
  CheckCarePlanQuesUpdateObj,
  CheckCPProblemRead,
  CheckCPProblemArr,
  FormateTcmSummary,
  CheckRecurringCPSearch,

  ///Checked
  CheckCPTeam,
  FormateApiQuesToTableQues,
};
